/* AwardsPrint.module.css */
.awardCard {
    box-sizing: border-box;
    width: 100%;
    height: 50%; /* Each card takes up half of the page height */
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc; /* Optional: adds a line between the cards */
}

@media print {
    body {
        width: 8.5in;
        height: 11in;
        margin: 0;
        padding: 0;
        page-break-after: avoid;
        page-break-before: avoid;
        page-break-inside: avoid;
    }
    .awardCard, .sectionMarker, .divisionAwards {
        page-break-inside: avoid; /* Avoid breaking inside an award card */
        break-inside: avoid; /* New CSS for the same effect */
        page-break-after: auto; /* Automatically manage page breaks after each card */
    }
}
